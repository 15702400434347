import React from "react";
import _ from "lodash";
import { markdownify } from "../utils";
import { Map, List } from ".";

export default class MapSection extends React.Component {
  render() {
    const markers = _.map(
      _.get(this.props, "section.markers"),
      (marker, marker_idx) => {
        const position = _.get(marker, "position");
        return {
          key: marker_idx,
          position: [_.get(position, "lat"), _.get(position, "long")],
          content: (
            <React.Fragment>
              <h4>{_.get(marker, "title")}</h4>
              {_.get(marker, "description") &&
                markdownify(_.get(marker, "description"))}
            </React.Fragment>
          ),
        };
      }
    );
    return (
      <React.Fragment>
        <section
          id={_.get(this.props, "section.section_id")}
          className="wrapper"
        >
          <div className="inner">
            <header className="major">
              <h2>{_.get(this.props, "section.title")}</h2>
              {_.get(this.props, "section.subtitle") &&
                markdownify(_.get(this.props, "section.subtitle"))}
            </header>
            <div className="map">
              <Map
                center={[46.927229, 2.655984]}
                zoom={6}
                style={{
                  width: "100%",
                  height: "500px",
                }}
                markers={markers}
              />
            </div>
          </div>
        </section>
        {_.get(this.props, "section.listTitle") && (
          <List
            section={{
              section_id: `${_.get(this.props, "section.section_id")}-details`,
              title: _.get(this.props, "section.listTitle"),
              list: _.get(this.props, "section.markers"),
              columns: 4,
              style: "carte",
            }}
          />
        )}
      </React.Fragment>
    );
  }
}
