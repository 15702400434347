import React from "react";
import _ from "lodash";

import { markdownify } from "../utils";

export default class RichText extends React.Component {
  render() {
    return (
      <section id={_.get(this.props, "section.section_id")} className="wrapper">
        <div className="inner">
          {(_.get(this.props, "section.title") ||
            _.get(this.props, "section.subtitle")) && (
            <header className="major">
              {_.get(this.props, "section.title") && (
                <h2>{_.get(this.props, "section.title")}</h2>
              )}
              {_.get(this.props, "section.subtitle") &&
                markdownify(_.get(this.props, "section.subtitle"))}
            </header>
          )}
          {_.get(this.props, "section.content") &&
            markdownify(_.get(this.props, "section.content"))}
        </div>
      </section>
    );
  }
}
