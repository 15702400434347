import AlternatedBlock from "./AlternatedBlock";
import Banner from "./Banner";
import Cta from "./Cta";
import Features from "./Features";
import Footer from "./Footer";
import GoogleForm from "./GoogleForm";
import Header from "./Header";
import List from "./List";
import Map from "./Map";
import MapSection from "./MapSection";
import Menu from "./Menu";
import Posts from "./Posts";
import Products from "./Products";
import RichText from "./RichText";
import Layout from "./Layout";

export {
  AlternatedBlock,
  Banner,
  Cta,
  Features,
  Footer,
  GoogleForm,
  Header,
  List,
  Map,
  MapSection,
  Menu,
  Posts,
  Products,
  RichText,
  Layout,
};

export default {
  AlternatedBlock,
  Banner,
  Cta,
  Features,
  Footer,
  GoogleForm,
  Header,
  List,
  Map,
  MapSection,
  Menu,
  Posts,
  Products,
  RichText,
  Layout,
};
