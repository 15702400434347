import React from "react";
import _ from "lodash";
import { Map as LeafletMap, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const markerIcon = `<svg viewBox="0 0 64 64" style="fill:currentColor;"><g><path d="m29 30c0 .551.448 1 1 1h4c.552 0 1-.449 1-1v-1h-6z"/><path d="m29 23h6v4h-6z"/><path d="m33.553 19.895c-.339-.17-.553-.516-.553-.895v-2h-2v2c0 .379-.214.725-.553.895-.495.247-.871.641-1.121 1.105h5.347c-.25-.465-.626-.858-1.12-1.105z"/><path d="m31 13h2v2h-2z"/><path d="m32 9c-7.168 0-13 5.832-13 13s5.832 13 13 13 13-5.832 13-13-5.832-13-13-13zm5 21c0 1.654-1.346 3-3 3h-4c-1.654 0-3-1.346-3-3v-7.764c0-1.544.753-2.951 2-3.805v-6.431c0-.552.447-1 1-1h4c.553 0 1 .448 1 1v6.431c1.247.854 2 2.261 2 3.805z"/><path d="m36.513 53.211-3.69 5.357c-.186.27-.494.432-.823.432s-.637-.162-.823-.433l-3.69-5.357c-6.841.644-10.487 2.587-10.487 3.79 0 1.631 5.844 4 15 4s15-2.369 15-4c0-1.203-3.646-3.146-10.487-3.789z"/><path d="m48.906 13.578c-3.086-6.257-8.988-10.201-15.787-10.55-.37-.018-.743-.028-1.119-.028s-.749.01-1.119.028c-6.799.349-12.701 4.293-15.787 10.55-3.212 6.514-2.67 14.243 1.414 20.172l15.492 22.487 15.492-22.487c4.084-5.929 4.626-13.658 1.414-20.172zm-16.906 23.422c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.729 15-15 15z"/></g></svg>`;

const PopupMarker = ({ content, position, icon }) => (
  <Marker position={position} icon={icon}>
    <Popup>{content}</Popup>
  </Marker>
);

const MarkersList = ({ markers, icon }) => {
  const items = markers.map(({ key, ...props }) => (
    <PopupMarker key={key} {...props} icon={icon} />
  ));
  return <React.Fragment>{items}</React.Fragment>;
};

export default class Map extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      const icon = L.divIcon({
        className: "marker-icon",
        iconAnchor: [25, 45],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -45],
        html: markerIcon,
      });
      return (
        <LeafletMap
          center={[46.927229, 2.655984]}
          zoom={6}
          style={{
            width: "100%",
            height: "500px",
          }}
        >
          <TileLayer
            maxZoom={19}
            subdomains="abcd"
            attribution='Map tiles by &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a> | Icons made by <a href="https://www.flaticon.com/authors/surang" title="surang">surang</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <MarkersList markers={_.get(this.props, "markers")} icon={icon} />
        </LeafletMap>
      );
    }
    return null;
  }
}
