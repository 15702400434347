import React from "react";
import _ from "lodash";

import { classNames, markdownify, Link, safePrefix } from "../utils";

export default class List extends React.Component {
  render() {
    return (
      <section id={_.get(this.props, "section.section_id")} className="wrapper">
        <div className="inner">
          <header className="major">
            <h2>{_.get(this.props, "section.title")}</h2>
            {_.get(this.props, "section.subtitle") &&
              markdownify(_.get(this.props, "section.subtitle"))}
          </header>
          <div className="posts">
            {_.map(
              _.get(this.props, "section.list"),
              (listItem, listItem_idx) => {
                const actions = _.get(listItem, "actions");
                return (
                  <section
                    key={listItem_idx}
                    className={
                      _.get(this.props, "section.style") === "carte"
                        ? "card"
                        : "post"
                    }
                    style={{
                      "--nb-col": _.get(this.props, "section.columns"),
                    }}
                  >
                    {_.get(listItem, "image") && (
                      <img
                        className="image"
                        src={safePrefix(_.get(listItem, "image"))}
                        alt=""
                      />
                    )}
                    <div className="content">
                      <h3>{_.get(listItem, "title")}</h3>
                      {_.get(listItem, "description") &&
                        markdownify(_.get(listItem, "description"))}
                      {actions &&
                        _.map(actions, (action, action_idx) => (
                          <Link
                            key={action_idx}
                            to={
                              _.get(action, "url").startsWith("#")
                                ? _.get(action, "url")
                                : safePrefix(_.get(action, "url"))
                            }
                            className={classNames(
                              "button",
                              "big",
                              { primary: _.get(action, "is_primary") },
                              { scrolly: _.get(action, "is_scrolly") }
                            )}
                          >
                            {_.get(action, "label")}
                          </Link>
                        ))}
                    </div>
                  </section>
                );
              }
            )}
          </div>
        </div>
      </section>
    );
  }
}
