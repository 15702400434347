import React from "react";
import _ from "lodash";

export default class GoogleForm extends React.Component {
  state = {
    hasLoadedOnce: false,
  };
  handleFrameLoad = () => {
    if (this.state.hasLoadedOnce) {
      document
        .getElementById(_.get(this.props, "section.section_id"))
        .scrollIntoView(true);
      window.scrollBy(0, -80);
    }
    this.setState({ hasLoadedOnce: true });
  };
  render() {
    return (
      <section id={_.get(this.props, "section.section_id")} className="wrapper">
        <div className="inner">
          <div
            className="contact-form-container"
            style={{
              "--frame-height": `${_.get(this.props, "section.frameheight")}px`,
              "--medium-screen-frame-height":
                _.get(this.props, "section.mediumscreenframeheight") &&
                `${_.get(this.props, "section.mediumscreenframeheight")}px`,
              "--small-screen-frame-height":
                _.get(this.props, "section.smallscreenframeheight") &&
                `${_.get(this.props, "section.smallscreenframeheight")}px`,
              "--xsmall-screen-frame-height":
                _.get(this.props, "section.xsmallscreenframeheight") &&
                `${_.get(this.props, "section.xsmallscreenframeheight")}px`,
            }}
          >
            <iframe
              className="responsive-iframe"
              src={`${_.get(this.props, "section.url")}?embedded=true`}
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title={_.get(this.props, "section.title")}
              onLoad={this.handleFrameLoad}
            >
              {_.get(this.props, "section.loadingLabel")}
            </iframe>
          </div>
        </div>
      </section>
    );
  }
}
