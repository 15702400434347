import React from "react";
import _ from "lodash";

import { classNames, Link } from "../utils";
import Menu from "./Menu";

export default class Header extends React.Component {
  render() {
    const lang = _.get(this.props, "lang");
    const menu = _.get(this.props, `pageContext.menus.main_${lang}`);
    let homePath = "/";
    if (lang !== "fr") {
      homePath = `/${lang}`;
    }
    return (
      <header
        id="header"
        className={classNames({
          alt:
            _.get(this.props, "pageContext.frontmatter.template") ===
            _.get(this.props, "pageContext.site.siteMetadata.landing_template"),
        })}
      >
        <Link to={_.get(this.props, "pageContext.site.pathPrefix") || homePath}>
          <div id="logo" className="header__logo">
            <img
              className="header__logo-img"
              src={_.get(this.props, "pageContext.site.siteMetadata.logo")}
              alt=""
            />
            {_.get(this.props, "pageContext.site.siteMetadata.title")}
          </div>
        </Link>
        <nav id="nav">
          <Menu {...this.props} menu={menu} page={this.props.pageContext} />
        </nav>
        <Link to="#navPanel" className="navPanelToggle" />
      </header>
    );
  }
}
